<template>
  <div class="body">
    <!-- banner区域 Start -->
    <div class="banner">
      <m-top-bar></m-top-bar>
    </div>
    <!-- banner区域 End -->

    <!-- switchBar区域 Start -->
    <div class="switchBar">
      <div class="switchBox">无线快速切换基站</div>
    </div>
    <div class="line"></div>
    <!-- switchBar区域 End -->

    <!-- container区域 Start -->
    <div class="container">
      <div class="containerBox">
        <img src="../../../../assets/mobile/img/product/AP780N.png" alt="" class="pic">
        <div class="title">无线快速切换基站</div>
        <div class="text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;无线快速切换基站系列产品是由宁讯开发推出的一款高性能、高稳定性的<br>
          无线接入点产品，专用于室内外无线覆盖。产品具有可靠、先进、功能丰富的<br>
          操作系统，可以快速、安全、高效的提供稳定高速的无线网络连接。此外，产<br>
          品还支持智能切换接入模式，在配合无线切换控制器可使连接客户进行快速切<br>
          换漫游。
        </div>
        <div class="title">产品特点</div>
        <div class="text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多种工作模式：支持接入点（自动WDS）、智能快速移动漫游等工作模式。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高效的私有无线传输协议：私有无线传输协议，通过有序的轮询每个终端，消除了标准WIFI协议的无线碰撞，提供最佳的多用户接入速率和更低的延时。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持智能切换接入模式：可配合宁讯无线控制器，进行AP大面积覆盖。控制器漫游机制优化配置也可支持第三方移动客户端漫游连接。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;移动快速切换：搭配优化智能算法的车载移动单元可搭建支持移动快速切换漫游的无线覆盖网络。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;丰富的内置实用工具：采用HTML5设计，内置天线校准，站点扫描，延迟重启，Ping，路由追踪，频谱分析等实用的安装辅助工具，为场地勘察和链路开通提供科学分析。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;选配内置或外置天线：根据不同应用场景选配不同增益的内置或外置天线、全向或定向天线，达到最佳使用效果。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多种供电模式：外接12-24 V直流供电，支持以太网接口供电。
        </div>
      </div>
    </div>
    <!-- container区域 End -->

    <!-- baseBar区域 Start -->
    <m-base-bar></m-base-bar>
    <!-- baseBar区域 End -->
  </div>
</template>
  
<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
  name: 'Support',
  components: {
    mTopBar,
    mBaseBar,
  }
}
</script>

<style scoped>
.body {
  background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
  width: 100%;
  min-height: 2050px;
  background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
  background-size: 335%;
}

/* switchBar区域 */
.switchBar {
  padding-top: 50px;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: #ffffff;
}

.switchBar .switchBox {
  margin: 0 auto;
  text-align: center;
  width: 700px;
  height: 200px;
  font-size: 72px;
  line-height: 200px;
  color: #ffffff;
  border-radius: 30px;
  background-color: #c3a35d;
}

.line {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
  padding: 50px;
  width: 100%;
  min-height: 500px;
}

.container .containerBox {
  padding: 100px;
  width: 100%;
  min-height: 300px;
  text-align: justify;
  background-color: #ffffff;
}

.container .containerBox .pic {
  margin-bottom: 150px;
  width: 1600px;
}

.container .containerBox .title {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 80px;
}

.container .containerBox .text {
  margin-bottom: 50px;
  font-size: 70px;
  color: #999;
}
</style>